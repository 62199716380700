/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* You can add global styles to this file, and also import other style files */
@import "_variables";

// Fonts
@font-face {
    font-family: "Font Awesome 5 Free";
    src: url("~/assets/fonts/fa-solid-900.ttf");
}

@font-face {
    font-family: "Font Awesome 5 Regular";
    src: url("~/assets/fonts/fa-regular-400.ttf");
}

@font-face {
    font-family: "Font Awesome 5 Brands";
    src: url("~/assets/fonts/fa-brands-400.ttf");
}

@font-face {
    font-family: "Roboto";
    src: url("~/assets/fonts/Roboto-Thin.ttf");
    font-weight: 100;
}

@font-face {
    font-family: "Roboto";
    src: url("~/assets/fonts/Roboto-ThinItalic.ttf");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("~/assets/fonts/Roboto-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Roboto";
    src: url("~/assets/fonts/Roboto-LightItalic.ttf");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("~/assets/fonts/Roboto-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Roboto";
    src: url("~/assets/fonts/Roboto-Italic.ttf");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("~/assets/fonts/Roboto-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Roboto";
    src: url("~/assets/fonts/Roboto-MediumItalic.ttf");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("~/assets/fonts/Roboto-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "Roboto";
    src: url("~/assets/fonts/Roboto-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("~/assets/fonts/Roboto-Black.ttf");
    font-weight: 900;
}

@font-face {
    font-family: "Roboto";
    src: url("~/assets/fonts/Roboto-BlackItalic.ttf");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("~/assets/fonts/RobotoCondensed-Regular.ttf");
    font-weight: normal;
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("~/assets/fonts/RobotoCondensed-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "RBno2.1a";
    src: url("~/assets/fonts/RBNo2.1a-Medium_BEARBEITET.otf");
    font-weight: normal;
}

@font-face {
    font-family: "RBno2.1a";
    src: url("~/assets/fonts/Rene Bieder - RBNo2.1a-Black.otf");
    font-weight: bold;
}

.fa-solid {
    font-family: "Font Awesome 5 Free";
}

.fa-regular {
    font-family: "Font Awesome 5 Regular";
}

.fa-brands {
    font-family: "Font Awesome 5 Brands";
}

.fs-roboto {
    font-family: "Roboto";
}

.fs-roboto-condensed {
    font-family: "Roboto Condensed";
}

h2 {
    font-size: 64px;
}

.fs-rb {
    font-family: "RBno2.1a";
}

.text-red {
    color: $ahz-customer-color;
}

input {
    border-color: white !important;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(225, 0, 25, 0.25) !important;
    }
}

// Pointer to mark something as clickable
.clickable {
    cursor: pointer;
}

// Show invalid feedback message for forms
.ng-invalid.ng-touched + .invalid-feedback {
    display: block;
}

// Colors for alert messages
.alert {
    box-shadow: $box-shadow;
    color: $alert-font-color;
    font-weight: bold;

    &--danger {
        background-color: $danger-color;
    }

    &--success {
        background-color: $success-color;
    }

    &--info {
        background-color: $info-color;
    }

    &--warning {
        background-color: $warning-color;
    }
}

// Class for white card which is wrapped around every component.
.white-card {
    padding: 3em;
    background: white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
}

// Styles for the header label
.header-label {
    width: 100%;
    color: $accent-background-color-mid;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;

    &--bottom-separator {
        border-top: 3px solid $accent-background-color-light;
        opacity: 0.6;
    }
}

.ahz-custom-checkbox {
    /* Customize the label (the ahz-checkbox-container) */
    .ahz-checkbox-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 1em;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .form-control-label {
            color: black;
            font-family: "Roboto";
            margin-left: 20px;
        }
    }

    /* Hide the browser's default checkbox */
    .ahz-checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        color: #000000;
        position: absolute;
        top: 0;
        left: 0;
        height: 31px;
        width: 31px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    /* On mouse-over, add a grey background color */
    .ahz-checkbox-container:hover input ~ .checkmark {
        background-color: #fff;
    }

    /* When the checkbox is checked, add a blue background */
    .ahz-checkbox-container input:checked ~ .checkmark {
        background-color: #fff;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .ahz-checkbox-container input:checked ~ .checkmark:after {
        display: block;
    }

    .ahz-check-icon img {
        margin-top: 4px;
        height: 22px;
        width: 27px;
    }

    .ahz-checkbox-container input:not(:checked) ~ .checkmark {
        .ahz-check-icon {
            display: none;
        }
    }
}

// Styles for the buttons
// .btn {
//     padding: 5px 30px;
//     border-radius: $btn-border-radius;
//     background-color: transparent;
//     text-align: center;
//     text-transform: uppercase;
//
//     &--secondary {
//         background-color: white;
//         border: 1px solid $accent-background-color-mid;
//         color: $accent-background-color-mid;
//     }
//
//     &--success {
//         background-color: $accent-background-color;
//         color: white;
//     }
//
//     &--disabled {
//         cursor: not-allowed !important;
//         opacity: 0.3;
//     }
// }

// Override cookieconsent's plugin classes
.cc-type-info {
    min-width: 90vw;
    right: 5vw;
    left: 5vw;
    top: 35vh;
    padding: 2em;

    .cc-compliance {
        display: block;
    }

    .cookie-banner-link--separator {
        color: $accent-font-color-mid !important;
        font-size: 1.3em;
        margin-top: 0.05em;
    }

    .cc-link {
        color: $accent-font-color-mid !important;
        font-weight: bold;
        text-decoration: none;
    }

    .cc-compliance {
        horizontal-align: center;
    }
}

label {
    color: $ahz-customer-color;
    font-family: "RBno2.1a";
    font-size: 28px;
    font-weight: bold;
}

// Edit icon for overviews.
.edit-icon,
.trash-icon {
    color: $font-color-dark;
}

// Add icon for overviews.
.add-icon {
    color: $ahz-customer-color;
    font-size: 16pt;
    border: 0px;
    background: none !important;
}

// Accent font color.
.accent-font-color-mid {
    color: $accent-font-color-mid;
}

.ng-multiselect-text-center {
    .dropdown-btn,
    .multiselect-item-checkbox {
        text-align: center !important;
    }
}

// Buttons.
.btn {
    border-radius: 10px;
    font-family: "RBno2.1a";
    font-size: 36px;
    line-height: 45px;
    padding-left: 56px;
    padding-right: 56px;
    padding-top: 7px;
    &-primary {
        color: white;
        text-transform: uppercase;
        background-color: $ahz-customer-color;
        border-color: $ahz-customer-color;

        &:hover {
            background-color: #000000;
            border-color: #000000;
        }
    }

    &-secondary {
        color: white;
        text-transform: uppercase;
        background-color: #000000;
    }

    &.btn-outlined {
        background-color: $background-color-light;
        color: $accent-font-color-mid;
        border: 1px solid $accent-font-color-mid;
    }

    &.btn-filled {
        background-color: $success-background-color;
        color: $font-color-light;
    }
}

// Dropdown Menu
.multiselect-dropdown {
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    .dropdown-btn {
        background-color: white;
        border-color: white !important;
    }

    .multiselect-item-checkbox input[type="checkbox"] {
        + div {
            padding: 6px 10px !important;
        }

        + div:before {
            display: none;
        }

        + div:after {
            display: none;
        }
    }

    .multiselect-item-checkbox input[type="checkbox"]:checked {
        + div {
            background-color: $ahz-customer-color !important;
            color: #fff !important;
        }
    }

    .selected-item {
        background-color: #fff !important;
        border-color: #fff !important;
        color: $font-color-dark !important;
        padding: 0px !important;
        margin: 0px !important;
        border: 0px !important;
        max-width: 100% !important;
    }

    .dropdown-multiselect__caret:before {
        color: $ahz-customer-color !important;
        border-color: $ahz-customer-color transparent !important;
    }
}

// Pagination
.ngx-pagination {
    padding-left: 0;

    .current {
        background-color: #fff !important;
        color: $success-background-color !important;
        font-weight: bold !important;
    }

    .pagination-next {
        color: $success-background-color !important;
    }

    .pagination-previous {
        color: $success-background-color !important;
    }
}

// Link
.link {
    color: $alert-font-color;
    text-decoration: underline;
}

// main-content has to be used for backbone layout (see as well media queries).
.main-content {
    font-family: "Roboto";
    min-height: calc(100vh - #{$primary-header-height} - #{$footer-height} - #{$secondary-header-height});
}

.ahz-background-image-fading {
    width: 100%;
    height: 80px;
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

@media (max-width: 992px) {
    .main-content {
        min-height: calc(
            100vh - #{$primary-header-height-collapsed} - #{$secondary-header-height-collapsed} - #{$footer-height}
        );
    }
}

@media (max-width: 767px) {
    .main-content {
        min-height: calc(
            100vh - #{$primary-header-height-collapsed} - #{$secondary-header-height-collapsed} - #{$footer-height-mobile}
        );
    }
}

@media (max-width: 575px) {
    h2 {
        font-size: 16pt;
    }

    .btn {
        font-size: 10pt;
    }

    h1 {
        font-size: 22pt;
    }
}
